jQuery(function(){
  jQuery('input[type=number]:not([no-proccess])').each(function(){

    if (jQuery(this).hasClass("vertical")){
      jQuery('<div class="quantity-button quantity-down">-</div>').insertBefore($(this));
      jQuery('<div class="quantity-button quantity-up">+</div>').insertAfter($(this));
    }
    else{
      jQuery('<div class="quantity-nav"><div class="quantity-button quantity-up"></div><div class="quantity-button quantity-down"></div></div>').insertAfter($(this));
    }

      var spinner = jQuery(this).parent(),
        input = spinner.find('input[type="number"]'),
        btnUp = spinner.find('.quantity-up'),
        btnDown = spinner.find('.quantity-down'),
        min = input.attr('min'),
        max = input.attr('max');
  
      btnUp.click(function() {
        var oldValue = parseFloat(input.val());
        if (oldValue >= max) {
          var newVal = oldValue;
        } else {
          var newVal = oldValue + 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
      });
  
      btnDown.click(function() {
        var oldValue = parseFloat(input.val());
        if (oldValue <= min) {
          var newVal = oldValue;
        } else {
          var newVal = oldValue - 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
      });

  })
})