// Sticky menu             
; (function () {
	var $dummy = $("#dummy");
	var $totop = $("#to-top");
	var height = $dummy.outerHeight(true);

	$(window).bind('scroll', function () {
		if ($(window).scrollTop() >= 250) {
			$("#to-top").addClass('top-visible');
			//$totop.addClass('top-visible');
		} else {
			$("#to-top").removeClass('top-visible');
			//$totop.removeClass('top-visible');
		}

		if ($dummy.length > 0) {
			var els = $dummy.data('els').split(',');
			if ($(window).scrollTop() >= height) {
				for (i in els) {
					$(els[i]).addClass('sticky');
				}
			} else {
				for (i in els) {
					$(els[i]).removeClass('sticky');
				}
			}
		}
	});
})()


// Toogle nav
$(function () {
	$('.collapse-button').click(function () {
		$(this).parent().toggleClass('opened');
	});
	// Menu Collapsed
	$('[data-toggle="collapse"]').click(function () {
		$('body').toggleClass('nav-main-collapsed');
		return false;
	})

	$('[data-toggle-class*="."]').click(function () {
		$($(this).data('toggleClass')).toggleClass('active');
		return false;
	})


	function fullwidth(el) {
		var width = (el.width() - $(window).width()) / 2;
		el.css({
			marginLeft: (Math.abs(width) * -1) + 'px',
			marginRight: (Math.abs(width) * -1) + 'px',
			paddingLeft: width + 'px',
			paddingRight: width + 'px'
		});
	}

	$('[data-fullwidth="true"]').each(function () {
		fullwidth($(this));
	})

	$(window).resize(function () {
		$('[data-fullwidth="true"]').each(function () {
			fullwidth($(this));
		})
	})

	$('[ext-video]').click(function () {
		var $el = $(this);
		if ($el.find('iframe').length == 0) {
			$el.find('img').remove();
			$el.html('<iframe src="' + $el.attr('ext-video') + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
			$el.addClass('with-video');
		}
	})
})