var Shop = {};

Shop.createCart = function (data) {
    Shop.vuesInstances = [];

    Shop.cart = new Vue({
        el: '#shop-mini-cart',
        data: {
            cart: data
        },
        methods: {
            updateCart: function (cart) {
                this.cart = cart;

                Array.prototype.forEach.call(Shop.vuesInstances, function (el, index) {
                    el.$data.cart = cart;
                });
            }
        }
    });

    if ($('#shop-checkout').length > 0) {
        Shop.checkout = new Vue({
            el: '#shop-checkout',
            data: {
                cart: data
            },
            methods: {
                updateCart: function (cart) {
                    Shop.checkout.cart = cart;
                }
            }
        });
    };

    Shop.vues = document.querySelectorAll("[shop-cart-data]");

    Array.prototype.forEach.call(Shop.vues, function (el, index) {
        var vue = new Vue({
            el: el,
            data: {
                cart: Shop.cart.cart
            },
            methods: {
                updateField: function(event, key, byDefault, customValue) {
                    if (['checkbox', 'radio'].indexOf(event.target.type) > -1) {
                        if (event.target.checked) {
                            if (customValue != null) {
                                var value = customValue;
                            } else {
                                var value = event.target.value;
                            }
                        } else {
                            var value = byDefault;
                        }
                    } else {
                        var value = event.target.value;
                    }

                    Shop.checkout.cart[key] = value;
                }
            }
        });

        Shop.vuesInstances.push(vue);
    });
}

Shop.updateCart = function (data) {
    Shop.cart.updateCart(data);

    if (Shop.checkout) {
        Shop.checkout.updateCart(data);
    }
}