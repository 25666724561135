$(function(){
  var controller = new slidebars();
  $('body').data( 'slidebars', controller);
  controller.init();
  $('body').delegate( '[data-slidebar], [data-slidebar-close]', 'click', function(){
    var id = $(this).data( 'slidebar');
    var $el = $('[off-canvas*=' + id + ']');
    var dummyId = 'canvas-' + id;
    if( controller.isActiveSlidebar( id)){
      setTimeout( function(){
        $('#'+ dummyId).after( $el);
        $('#'+ dummyId).remove();
      }, 500);
    } else {
      $el.after( '<span style="display: none" id="' + dummyId + '"></span>');
      $el.prependTo( 'body');
    }
  });

  $('body').delegate( '[data-slidebar]', 'click', function(){
    controller.toggle( $(this).data( 'slidebar'));
    return false;
  })



  $('body').delegate( '[data-slidebar-close]', 'click', function( e){
    controller.toggle( $(this).data( 'slidebar'));
    return false;
  })

  $('[off-canvas*=left]').on( 'swipeleft', function( event){
    var name = $(this).attr( 'off-canvas').split(' ')[0];
    controller.toggle( name);
  })

  $('[off-canvas*=right]').on( 'swiperight', function( event){
    var name = $(this).attr( 'off-canvas').split(' ')[0];
    controller.toggle( name);
  })
})