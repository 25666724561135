var Store = {};
Handlebars.registerHelper("checkedIf", function (condition) {
    return (condition) ? "checked" : "";
});

(function () {
    $(function () {
        Store.isFiredSubmit = false;

        Store.langs = {
            closeSwal: 'OK'
        };

        Store.setLangs = function (langs) {
            Store.langs = $.extend(Store.langs, langs);
        }

        Store.submitOrder = function (form) {
            return $.post('/' + document.locale + '/store/orders/update.json', form.serialize());
        };

        Store.cart = $("#mini-cart-template").length > 0 ? Handlebars.compile($("#mini-cart-template").html()) : false;
        Store.lineItems = $("#cart-line-items").length > 0 ? Handlebars.compile($("#cart-line-items").html()) : false;
        Store.totals = $("#cart-totals").length > 0 ? Handlebars.compile($("#cart-totals").html()) : false;
        Store.points = $("#cart-points").length > 0 ? Handlebars.compile($("#cart-points").html()) : false;
        Store.deliveryDate = $(".tpl-delivery-date").length > 0 ? Handlebars.compile($(".tpl-delivery-date").html()) : false;
        Store.deliveryDays = $(".tpl-delivery-days").length > 0 ? Handlebars.compile($(".tpl-delivery-days").html()) : false;
        Store.orderSubmit = $("#tpl-order-submit").length > 0 ? Handlebars.compile($("#tpl-order-submit").html()) : false;

        function observeInput(el) {
            if ($(el).val() == '') {
                $(el).removeClass('not-empty');
            } else {
                $(el).addClass('not-empty');
            }
        }

        // Update cart
        Store.updateCart = function (data) {
            if (Shop) {
                Shop.updateCart(data);
            }
            $('body').trigger('updateCart', [data]);
            if (Store.cart) {
                var html = Store.cart(data);
                $('.mini-cart').html(html);
            }

            if (Store.lineItems) {
                var html = Store.lineItems(data);
                $('.cart-line-items').html(html);
            }

            if (Store.totals) {
                var html = Store.totals(data);
                $('.cart-totals').html(html);
            }

            if (Store.deliveryDate) {
                var html = Store.deliveryDate(data);
                $('.tpl-delivery-date').html(html);
            }

            if (Store.orderSubmit) {
                var html = Store.orderSubmit(data);
                $('.tpl-order-submit').html(html);
            }

            if (Store.points) {
                var html = Store.points(data);
                $('.cart-points').html(html);
            }

            if (Store.deliveryDays) {
                var html = Store.deliveryDays(data);
                $('.tpl-delivery-days').html(html);

                if (data.express_delivery) {
                    $("#orders-express-delivery-1").attr('checked', 'checked');
                    $("#orders-express-delivery-0").attr('checked', false);
                } else {
                    $("#orders-express-delivery-0").attr('checked', 'checked');
                    $("#orders-express-delivery-1").attr('checked', false);
                }
            }

            if ($('.cart-user-data').length > 0) {
                $('[cart-error]').removeClass('show');
                $('[cart-error]').removeClass('u-show');
                $('._checkout-error').removeClass('_checkout-error');
                if (!$.isEmptyObject(data.errors) && Store.isFiredSubmit) {
                    var fields_errors = [];
                    $.each(data.errors, function (key, value) {
                        var html = [];
                        var _key = key;
                        $.each(value, function (key, value) {
                            html.push(value);
                        });

                        $('[cart-error="' + _key + '"]').html(html.join(', ')).addClass('show').addClass('u-show');
                        var field_error = $('[name="Orders[' + key + ']"]');
                        field_error.addClass('_checkout-error');
                        fields_errors.push(field_error);
                    })

                    $.scrollTo(fields_errors[0], 800, {
                        offset: {
                            top: -200
                        },
                        axis: 'y'
                    });
                }
            }

            if (data.has_items) {
                $('[cart-items-number]')
                    .attr('number', data.line_items.length)
                    .find('small')
                    .html(data.line_items.length)
                    .addClass('active')
                    .attr('number', data.line_items.length);
            } else if (data && data.line_items) {
                $('[cart-items-number]')
                    .attr('number', data.line_items.length)
                    .find('small')
                    .html('')
                    .removeClass('active')
                    .attr('number', 0);
            }
        }

        if ($("[chart-checkout]").length > 0) {
            Store.checkout = Handlebars.compile($("[chart-checkout]").html());
        }

        Store.updateCartCheckout = function (data) {
            var html = Store.checkout(data);
            $("[chart-checkout]").html(html);
        }



        // Order same address
        function changeSameAddress(el) {
            var $el = $($(el).attr('order-same-address'));

            if ($(el).is(':checked')) {
                $el.hide();
            } else {
                $el.show();
            }
        }

        changeSameAddress('[order-same-address]');

        $('body').on('cartError', function (e, data) {
            if (typeof(Swal) != 'undefined') {
                Swal.fire({
                    title: data.error.title,
                    text: data.error.text,
                    icon: 'warning',
                    button: {
                        text: Store.langs.closeSwal,
                    }
                })
            }
            else if (typeof (swal) != 'undefined') {
                swal({
                    title: data.error.title,
                    text: data.error.text,
                    icon: 'error',
                    button: {
                        text: Store.langs.closeSwal,
                    }
                })
            }
        });

        // Order same address
        function changeCreateAccount(el) {
            var $el = $($(el).attr('order-create-account'));

            if ($(el).is(':checked')) {
                $el.show();
            } else {
                $el.hide();
            }
        }

        // Points
        Store.addPoints = function (points) {
            $.post('/' + document.locale + '/store/orders/addpoints.json', {
                points: points
            })
                .done(function (data) {
                    Store.updateCart(data.cart);
                })
        }

        $('[order-create-account]').change(function () {
            changeCreateAccount(this);
        });

        changeCreateAccount('[order-create-account]');



        // 
        // EVENTOS DE CLICK Y CHANGE
        // 

        // Checkout express change form inputs
        $('body').delegate("[checkout-express] input:not([type=hidden]):not(.store-item-quantity-input):not([name=order-coupon]):not([no-update]), [checkout-express] select", 'change', function () {
            var form = $(this).closest("form");
            $.post('/' + document.locale + '/store/orders/update.json', form.serialize())
                .done(function (data) {
                    Store.updateCart(data.cart);
                })
        });

        // Add Item
        $('body').delegate('[data-add-item]', 'click', function () {
            var _this = $(this);
            if (_this.attr('disable')) {
                return;
            }
            _this.attr('disable', true);
            var attributes = [];
            var values = $('[name^=attributes]').serializeArray();
            _this.trigger('loading');
            for (i in values) {
                var name = values[i].name;
                var group_id = name.replace('attributes[', '').replace(']', '');
                attributes.push({
                    group_id: group_id,
                    attribute_id: values[i].value
                });
            }

            var line_item = {};
            var extras = $('[line-item]').serializeArray();

            for (i in extras) {
                line_item[extras[i].name] = extras[i].value;
            }
            var salt = $(this).data('addItem');
            var data = {
                salt: salt,
                attributes: attributes,
                line_item: line_item
            };

            if ($('[data-quantity-item=' + $(this).data('addItem') + ']').length > 0) {
                data.quantity = $('[data-quantity-item=' + $(this).data('addItem') + ']').val();
            }

            $.post('/' + document.locale + '/store/orders/additem.json', {
                product: data
            })
                .done(function (data) {
                    _this.trigger('stoping');

                    if (data.result.errors) {
                        _this.trigger('addItemError');
                        html = [];
                        for (i in data.result.errors) {
                            if (typeof (swal) != 'undefined'  || typeof (Swal) != 'undefined') {
                                html.push(data.result.errors[i]);
                            } else {
                                html.push('<span>' + data.result.errors[i] + '</span>');
                            }
                        }


                        console.log(typeof (swal) );

                        if (typeof(Swal) != 'undefined') {
                            Swal.fire({
                                text: html.join(''),
                                icon: 'warning',
                                button: {
                                    text: Store.langs.closeSwal,
                                }
                            })
                        } else if (typeof (swal) != 'undefined') {
                            swal({
                                text: html.join(''),
                                icon: 'warning',
                                button: {
                                    text: Store.langs.closeSwal,
                                }
                            })
                        } else {
                            $('[store-add-error="' + salt + '"]').html('<div class="store-add-error">' + html.join('') + '</div>');
                        }

                    }

                    if (!data.result.valid) {
                        return;
                    }

                    Store.updateCart(data.result.order);

                    if (_this.attr('data-add-item-redirect')) {
                        window.location.href = _this.attr('data-add-item-redirect');
                    } else {
                        var slidebars = $('body').data('slidebars');
                        if (slidebars && !slidebars.isActiveSlidebar('cart')) {
                            slidebars.open('cart')
                        }
                        _this.trigger('addItemSuccess');
                    }

                    $('body').trigger('storeAddItem', [data.result.item, data.result.order]);
                })
                .fail(function () {
                    _this.trigger('addItemError');
                })
                .always(function () {
                    _this.removeAttr('disable')
                });

        })

        // Submit cart
        $('body').delegate('[cart-submit]', 'click', function (e) {
            e.preventDefault();
            var _this = $(this);
            if (_this.attr('disable')) {
                return;
            }
            _this.attr('disable', true);
            $(this).trigger('loading');
            var form = $('[checkout-express]');

            $.post('/' + document.locale + '/store/orders/update.json?submit=true', form.serialize())
                .done(function (data) {
                    Store.isFiredSubmit = true;

                    if (data.cart.is_valid) {
                        window.location.href = _this.attr('href');
                    } else {
                        Store.updateCart(data.cart);
                        _this.trigger('stoping');
                        $('.checkout-flash').addClass('active');
                        setTimeout(function () {
                            $('.checkout-flash').removeClass('active');
                        }, 2000);

                        if (data.redirect) {
                            window.location.href = data.redirect;
                        }

                        $('body').trigger('cartError', [data])
                    }
                })
                .always(function () {
                    _this.removeAttr('disable');
                });

            return false;
        });

        // Add / Remove quantity
        $('body').delegate('[store-item-quantity]', 'click', function (e) {
            e.preventDefault();
            var action = $(this).attr('action');
            var id = $(this).attr('store-item-quantity');

            $.post('/' + document.locale + '/store/orders/quantity.json', {
                id: id,
                action: action
            })
                .done(function (data) {
                    if (!data.cart.line_items || (data.cart.line_items && data.cart.line_items.length == 0)) {
                        window.location.reload();
                    }

                    if (data.errors) {
                        var html = [];

                        for (i in data.errors) {
                            html.push(data.errors[i]);
                        }

                        if (typeof (swal) != 'undefined') {
                            new swal({
                                text: html.join(''),
                                icon: 'warning',
                                button: {
                                    text: Store.langs.closeSwal,
                                }
                            })
                        } else if (typeof(Swal) != 'undefined') {
                            Swal.fire({
                                text: html.join(''),
                                icon: 'warning',
                                button: {
                                    text: Store.langs.closeSwal,
                                }
                            })
                        }
                    }


                    Store.updateCart(data.cart);

                    if (action == 'add') {
                        $('body').trigger('storeAddItem', data.item, data.cart);
                    }

                    if (action == 'remove') {
                        $('body').trigger('storeDeleteItem', data.item);
                    }
                })
        });

        // Remove Item
        $('body').delegate('[store-delete-item]', 'click', function () {
            $(this).trigger('loading');
            $.post('/' + document.locale + '/store/orders/deleteitem.json', {
                id: $(this).attr('store-delete-item')
            })
                .done(function (data) {
                    $(this).trigger('stoping');

                    if (!data.order.line_items || data.order.line_items.length == 0) {
                        window.location.reload();
                    }
                    Store.updateCart(data.order);
                    $('body').trigger('storeDeleteItem', data.item);
                })
        })

        // Refund item
        $('body').delegate('[refund-item]', 'click', function (e) {
            e.preventDefault();
            var _this = $(this);

            if (typeof(Swal) != 'undefined') {
                Swal.fire({
                    text: _this.attr('prompt'),
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    button: {
                        text: Store.langs.closeSwal,
                    }
                }).then(function(value) {
                    if(value.isConfirmed) {
                        _this.trigger('loading');
                        $.post('/' + document.locale + '/store/orders/refund-line-item.json', {
                                item: _this.attr('refund-item'),
                                quantity: _this.attr('refund-quantity') ? _this.attr('refund-quantity') : 1
                            })
                            .done(function (data) {
                                if (data.success) {
                                    _this.trigger('success');
                                } else {
                                    _this.trigger('error');
                                }
                            })
                    }
                });
            }

        })

        // Change address
        $('body').delegate('[for=orders-same-address]', 'click', function () {
            setTimeout(function () {
                changeSameAddress($('[order-same-address]'));
            }, 10);
            $(this).trigger('change');
        });

        $('body').delegate('#orders-same-address', 'click', function () {
            setTimeout(function () {
                changeSameAddress($('[order-same-address]'));
            }, 10);
        });


        // Change quantity input text
        $('body').delegate('[store-item-quantity-input]', 'change', function (e) {
            e.preventDefault();
            var salt = $(this).attr('store-item-quantity-input');

            $.post('/' + document.locale + '/store/orders/quantity.json', {
                salt: salt,
                action: 'add',
                quantity: $(this).val()
            })
                .done(function (data) {
                    Store.updateCart(data.cart);
                })
        });

        // Remove fav
        $('body').delegate('[store-remove-fav]', 'click', function (e) {
            var _this = $(this);
            e.preventDefault();
            var salt = _this.attr('store-remove-fav');

            $.post('/' + document.locale + '/store/favs/delete.json', {
                product: salt
            })
                .done(function (data) {
                    if (data.result) {
                        $('#fav_' + salt).remove();

                        if (data.count == 0) {
                            window.location.reload();
                        }
                    }
                })
        });

        //  Favs
        $('body').delegate('[store-add-fav]', 'click', function (e) {
            var _this = $(this);
            e.preventDefault();
            var salt = _this.attr('store-add-fav');
            if (!_this.attr('store-remove-added')) {
                $.post('/' + document.locale + '/store/favs/add.json', {
                    product: salt
                })
                    .done(function (data) {
                        if (data.success) {
                            if (!_this.attr('store-add-fav-noadd')) {
                                _this.parent().html(data.success);
                            }

                            _this.attr('store-remove-added', true);
                            _this.trigger('storeAddedFav');
                            $('body').trigger('storeAddedFav', [data]);
                        }
                        if (data.error) {
                            if (!_this.attr('store-add-fav-noadd')) {
                                _this.parent().html(data.error);
                            }
                        }
                    })
            } else {
                $.post('/' + document.locale + '/store/favs/delete.json', {
                    product: salt
                })
                    .done(function (data) {
                        if (data.result) {
                            _this.removeAttr('store-remove-added');
                            _this.trigger('storeRemovedFav');
                        }
                    })
            }

        });

        // Change country
        $('body').delegate("[change-country]", 'change', function (e) {
            var $el = $($(this).attr('change-country'));
            $.post('/' + document.locale + '/store/orders/states.json', {
                country_id: $(this).val()
            })
                .done(function (data) {
                    $el.empty(); // remove old options
                    if ($el.attr('empty-label')) {
                        $el.append($("<option></option>")
                            .attr("value", '').text($el.attr('empty-label')));
                    }
                    $.each(data.states, function (key, value) {
                        $el.append($("<option></option>")
                            .attr("value", value.id).text(value.title));
                    });
                })
        });

        // Coupon
        $('body').delegate("[store-coupon]", 'click', function (e) {
            var value = $('[name="order-coupon"]').val();
            $.post('/' + document.locale + '/store/orders/coupon.json', {
                code: value
            })
                .done(function (data) {
                    if (data.error) {
                        $('[store-coupon-message]')
                            .removeClass('u-hide')
                            .text(data.error);
                    } else {
                        Store.updateCart(data.cart);
                    }
                })
        });

        $('body').delegate("[store-remove-coupon]", 'click', function (e) {
            $.post('/' + document.locale + '/store/orders/couponremove.json', {})
                .done(function (data) {
                    Store.updateCart(data.cart);
                })
        });
    })

})();
