$(function(){
  $('[off-canvas] a.has-childrens').click(function(){
      if( $(window).width() < 769){
        if( !$(this).data( 'clicked') && !$(this).parent().hasClass( 'current')){
          $(this).parent().addClass( 'current');
          $(this).data( 'clicked', true);
          return false;
        }
      }
    });
})